<template>
  <div class="Medicalproduct">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="设备药品名称">
            <el-input v-model="form.medicalsetName" placeholder="设备药品名称" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="medicalsetName" label="设备药品名称" align="center"></el-table-column>
          <el-table-column prop="medicalsetFactory" label="设备药品厂家" align="center"></el-table-column>
          <el-table-column prop="medicalsetType" label="类型" align="center">
            <template slot-scope="scope">
              <span
                effect="dark"
                type="success"
                v-if="scope.row.medicalsetType==='0'"
              >{{ '医疗设备' }}</span>
              <span effect="dark" v-else>{{ '药品' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="medicalsetMoney" label="设备药品费用(单价/元)" align="center"></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span effect="dark" type="success" v-if="scope.row.status==='0'">{{ '新增' }}</span>
              <span effect="dark" v-else-if="scope.row.status==='1'">{{ '正常' }}</span>
              <span type="info" effect="dark" v-else>{{ '停用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">车载硬盘数据备份详情</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="selectFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="设备药品名称" prop="medicalsetName">
              <el-input
                v-model="selectForm.medicalsetName"
                autocomplete="off"
                clearable
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备药品厂家" prop="medicalsetFactory">
              <el-input
                v-model="selectForm.medicalsetFactory"
                autocomplete="off"
                clearable
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="设备药品费用(单价/元)" prop="medicalsetMoney">
              <el-input
                v-model="selectForm.medicalsetMoney"
                autocomplete="off"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                clearable
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="类型" prop="medicalsetType">
              <el-select
                v-model="selectForm.medicalsetType"
                placeholder="请选择用户类型"
                filterable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.roleName"
                  :value="item.uuid"
                  v-for="(item, index) in medicalsetTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备药品编号" prop="medicalsetSn">
              <el-input
                v-model="selectForm.medicalsetSn"
                autocomplete="off"
                clearable
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="selectForm.status"
                placeholder="请选择状态"
                filterable
                :disabled="isDisabled"
              >
                <el-option label="新增" value="0"></el-option>
                <el-option label="正常" value="1"></el-option>
                <el-option label="停用" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="selectForm.remark"
                autocomplete="off"
                clearable
                :disabled="isDisabled"
              ></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="设备药品图片" prop="medicalsetUrl" v-model="selectForm.medicalsetUrl">
              <el-upload
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                ref="upload"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!isDisabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file,fileList)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加医疗设备或药品</span>
        <el-form :model="addForm" inline ref="addForm" :rules="addFormRules" class="addForm">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="设备药品名称" prop="medicalsetName">
              <el-input v-model="addForm.medicalsetName" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="设备药品厂家" prop="medicalsetFactory">
              <el-input v-model="addForm.medicalsetFactory" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="设备药品费用(单价/元)" prop="medicalsetMoney">
              <el-input
                v-model="addForm.medicalsetMoney"
                autocomplete="off"
                onkeyup="value=value.replace(/[^\d]/g,'')"
                clearable
              ></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="类型" prop="medicalsetType">
              <el-select v-model="addForm.medicalsetType" placeholder="请选择用户类型" filterable>
                <el-option
                  :label="item.roleName"
                  :value="item.uuid"
                  v-for="(item, index) in medicalsetTypeList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="设备药品编号" prop="medicalsetSn">
              <el-input v-model="addForm.medicalsetSn" autocomplete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable>
                <el-option label="新增" :value="0"></el-option>
                <el-option label="正常" :value="1"></el-option>
                <el-option label="停用" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <!-- 第三行 -->
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off" clearable></el-input>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="设备药品图片" prop="medicalsetUrl" v-model="addForm.medicalsetUrl">
              <el-upload
                action="#"
                list-type="picture-card"
                :auto-upload="false"
                :on-change="handleChange"
                :file-list="fileList"
                ref="upload"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div slot="file" slot-scope="{file}">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt />
                  <span class="el-upload-list__item-actions">
                    <span
                      class="el-upload-list__item-preview"
                      @click="handlePictureCardPreview(file)"
                    >
                      <i class="el-icon-zoom-in"></i>
                    </span>
                    <span
                      v-if="!isDisabled"
                      class="el-upload-list__item-delete"
                      @click="handleRemove(file,fileList)"
                    >
                      <i class="el-icon-delete"></i>
                    </span>
                  </span>
                </div>
              </el-upload>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 附件预览弹出框 -->
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt />
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'vehicleequipmenthdd',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { medicalsetName: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        medicalsetName: [
          { required: true, message: '请输入设备药品名称', trigger: 'blur' }
        ],
        medicalsetFactory: [
          { required: true, message: '请输入设备药品厂家名称', trigger: 'blur' }
        ],
        medicalsetMoney: [
          { required: true, message: '请输入设备药品费用', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        medicalsetName: [
          { required: true, message: '请输入设备药品名称', trigger: 'blur' }
        ],
        medicalsetFactory: [
          { required: true, message: '请输入设备药品厂家名称', trigger: 'blur' }
        ],
        medicalsetMoney: [
          { required: true, message: '请输入设备药品费用', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择类型', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      medicalsetTypeList: [{ roleName: '医疗设备', uuid: '0' }, { roleName: '药品', uuid: '1' }]
    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有车载设备硬盘数据备份记录列表
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoMedicalset/getTInfoMedicalsetList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
        // "status": 1
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取失败')
      this.tabData = res.data
      this.total = +res.pageBean.pageDataCount
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.medicalsetName === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        this.paging.PageNo = 1
        const { data: res } = await this.$http.post('/pmService/tInfoMedicalset/getTInfoMedicalsetList', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          medicalsetName: this.form.medicalsetName
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        console.log(res)
        this.tabData = res.data
        this.total = +res.pageBean.pageDataCount
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      console.log(PageNum)
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.medicalsetName}`, '删除医用设备或药品', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/pmService/tInfoMedicalset/deleteTInfoMedicalset', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除车载设备信息失败')
          // 成功提示
          this.$message.success('删除车载设备信息成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          // 进行编辑操作
          const { data: res } = await this.$http.post('/pmService/tInfoMedicalset/updateTInfoMedicalset', JSON.parse(JSON.stringify(this.selectForm)))
          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改车载设备信息失败')
          // 成功提示
          this.$message.success('修改车载设备信息成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加机构--确定按钮
    addSure () {
      // console.log(JSON.parse(JSON.stringify(this.addForm)));
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        this.addForm.transportTypeId = +this.addForm.transportTypeId
        this.addForm.companyId = +this.addForm.companyId
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/pmService/tInfoMedicalset/addTInfoMedicalset', JSON.parse(JSON.stringify(this.addForm)))
        // 判断请求是否成功
        console.log(res)
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.fileList = []
      this.$refs.addForm.resetFields()
    },
    handleRemove (file) {
      const fileList = this.$refs.upload.uploadFiles
      const index = fileList.findIndex(fileItem => {
        return fileItem.uid === file.uid
      })
      fileList.splice(index, 1)
      const i = this.fileList.findIndex(fileItem => {
        return fileItem.uid === file.uid
      })
      this.fileList.splice(i, 1)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 添加附件时触发
    handleChange (file, fileList) {
      if (fileList.length > 3) {
        this.$message.warning('最多上传三张图片')
        fileList.splice(fileList.length - 1, 1)
      } else {
        this.fileList.push(file)
        this.addForm.file = this.fileList
      }
    }
  }
}
</script>
<style lang="less" scoped>
.Medicalproduct {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
